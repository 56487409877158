
























































































import { Vue, Component } from "vue-property-decorator";
import { SaleArticle, SalePDF } from "@/types/sales/pdf";

// hier folgen die Bild und PDF Dateien für Sales
@Component
export default class Sale extends Vue {
    private privatePDFs: SalePDF[] = [
        {
            name: "Windows11 Update und E-Rechnungen",
            pdf: "./pdfs/Flyer_Windows11_E_Rechnungen.pdf",
            img: require("@/assets/pdf/img/Flyer_Windows11_E_Rechnungen.jpg"),
        },
    ];

    private privateArticles: SaleArticle[] = [];

    private businessArticles: SaleArticle[] = [];
    private businessCustomer = false;
}
